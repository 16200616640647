<template>
  <div class="facebook">
    <page-table
      :searchs="searchs"
      :btns="btns"
      :tableColumn="tableColumn"
      :formatterParams="formatterParams"
      :API="ListAPI"
      ref="pageTable"
    ></page-table>
    <!-- @selectionChange="selectionChange" -->
    <!--  FB开户 -->
    <el-dialog
      v-if="showDialog"
      title="提交开户申请-FB"
      :visible.sync="showDialog"
      width="800px"
      @close="toggleShowDialog(false)"
      :close-on-click-modal="false"
    >
      <open-account
        @cancel="toggleShowDialog(false)"
        @refreshList="refreshList"
        :queryAgency="queryAgency"
      ></open-account>
    </el-dialog>
  </div>
</template>
<script>
import PageTable from '@/components/common/PageTable.vue';
import SearchGroup from '@/components/common/SearchGroup.vue';
import OpenAccount from './common/OpenAccount.vue';
import { queryOpenAccountPage } from '@/api/OpenAccountList';
import { queryAgency } from '@/api/common/account';

const Status = {
  FAILED: '失败',
  BF_IN_REVIEW: 'BF审核中',
  BF_REJECT: 'BF驳回',
  FB_IN_REVIEW: 'FB审核中',
  FB_REJECT: 'FB驳回',
  FINISHED: '开户完成',
  CANCEL: '取消',
  SUBMIT_AUDIT_TASK: '提交审核任务',
};
const searchs = [
  {
    type: 'input',
    prop: 'requestId',
    formOptions: {
      placeholder: '开户申请ID',
      clearable: true,
    },
  },
  {
    type: 'input',
    prop: 'entityName',

    formOptions: {
      placeholder: '开户主体',
      clearable: true,
    },
  },
  {
    type: 'select',
    prop: 'agencyId',
    formOptions: {
      placeholder: '账单简称',
      clearable: true,
      filterable: true,
    },
    optionConfig: {
      label: 'agency',
      value: 'id',
    },
    options: [],
  },
  {
    type: 'select',
    prop: 'openAccountStatus',
    formOptions: {
      placeholder: '开户状态',
      clearable: true,
    },
    options: Object.keys(Status).map((item) => ({ label: Status[item], value: item })),
  },
  {
    type: 'date-picker',
    prop: 'time',
    formOptions: {
      type: 'daterange',
      clearable: true,
      'range-separator': '-',
      'value-format': 'yyyy-MM-dd',
    },
  },
];
export default {
  components: {
    SearchGroup,
    PageTable,
    OpenAccount,
  },
  data() {
    return {
      searchs,
      btns: [
        {
          name: 'FB开户',
          click: () => this.toggleShowDialog(true),
          config: {
            type: 'primary',
          },
        },
      ],
      tableColumn: [
        {
          type: 'selection',
        },
        {
          label: '申请ID',
          prop: 'fbRequestId',
        },
        {
          label: '开户批次号',
          prop: 'batchNo',
        },
        {
          label: '开户主体',
          prop: 'entityName',
        },
        {
          label: '开户域名',
          prop: 'promotableUrls',
        },
        {
          label: '开户主页',
          prop: 'promotablePageIds',
          width: 300,
        },
        {
          label: '开户数量',
          prop: 'openNum',
          align: 'right',
          width: 100,
        },
        {
          label: '提交状态',
          prop: 'status',
          width: 100,
          formatter: (row) => {
            if (row.status == 'FINISHED') return <span class='status-409eff'>{Status[row.status]}</span>;
            if (row.status == 'FAILED')
              return (
                <el-tooltip
                  effect='dark'
                  content={row.failReason}
                  placement='bottom'
                >
                  <span class='status-e44646'>{Status[row.status]}</span>
                </el-tooltip>
              );
            return Status[row.status];
          },
        },
        {
          label: '账单简称',
          prop: 'agency.agencyName',
          width: 250,
        },
        {
          label: '开户时间',
          prop: 'createTime',
          width: 200,
        },
        {
          label: '操作',
          width: 120,
          btns: [
            {
              text: '详情',
              type: 'primary',
              size: 'mini',
              hidden: (scope) => scope.row.status !== 'FINISHED',
              click: ({ scope }) => this.detailClick(scope.row),
            },
          ],
        },
      ],
      showDetail: false,
      showDialog: false, // 显示开户弹框
      ListAPI: queryOpenAccountPage,
      queryAgency: [],
    };
  },
  methods: {
    // 获取账单简称
    getQueryAgency() {
      queryAgency().then((res) => {
        this.queryAgency = res.data;
        this.searchs.forEach((item, index) => {
          if (item.prop === 'agencyId') {
            this.$set(this.searchs[index], 'options', res.data);
          }
        });
      });
    },
    // 修改开户展示状态
    toggleShowDialog(bool = false) {
      this.showDialog = bool;
    },
    // // 选择的数据
    // selectionChange(selection) {
    //   this.selectList = selection;
    // },
    // 点击详情
    detailClick(data) {
      this.$emit('toggleShowDetail', true);
      this.$emit('setOpearData', data);
    },
    // 格式化接口入参
    formatterParams({ time, ...otherParmas }) {
      let newParams = {
        ...otherParmas,
      };
      if (time) {
        newParams = {
          ...otherParmas,
          startDate: time[0],
          endDate: time[1],
        };
      }
      if (!newParams.openAccountStatus) {
        delete newParams.openAccountStatus;
      }
      return newParams;
    },
    refreshList() {
      this.$refs.pageTable.getList({
        pageNumber: 0,
      });
    },
  },
  created() {
    this.getQueryAgency();
  },
};
</script>
<style lang="scss" scoped>
.facebook {
}
</style>
