<template>
  <div class="openAccountFb dp-flex flex-d-c flex-1">
    <el-container>
      <!-- <el-header class="border-bt-1 dp-flex align-item-c">提交开户申请-FB</el-header> -->
      <el-main>
        <el-form
          :model="openAccount"
          :rules="rules"
          label-position="top"
          ref="form"
        >
          <el-form-item
            label="账单简称："
            prop="id"
          >
            <el-select
              placeholder="选择代理账单"
              class="formItem"
              filterable
              v-model="openAccount.id"
            >
              <el-option
                :label="query.hasConfigVlToken ? query.agency : query.agency + ''"
                :value="query.id"
                v-for="query in queryAgency"
                :key="query.id"
                :disabled="!query.hasConfigVlToken"
              >
                <div class="dp-flex justify-btw">
                  <span>{{ query.agency }}</span>
                  <span v-if="!query.hasConfigVlToken">（请配置vlToken）</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="申请ID："
            prop="requestIds"
          >
            <el-input
              placeholder="输入多个可用,隔开"
              v-model="openAccount.requestIds"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-link disabled
          >需在facebook提价开户申请后获取申请ID。<el-link
            type="primary"
            @click="setShowGetId(true)"
            >获取申请ID</el-link
          ></el-link
        >
      </el-main>
      <el-footer class="border-top-1 dp-flex justify-end align-item-c">
        <el-button
          type="primary"
          @click="checkData"
          >确认</el-button
        >
        <el-button
          type="info"
          @click="$emit('cancel')"
          >取消</el-button
        >
      </el-footer>
    </el-container>
    <el-dialog
      title=""
      width="60%"
      :visible="showGetId"
      append-to-body
      @close="setShowGetId(false)"
    >
      <img
        src="@/assets/image/lookGetId.png"
        alt="查看申请ID"
        class="lookGetIdImg"
      />
    </el-dialog>
  </div>
</template>

<script>
// import { queryAgency } from '@/api/common/account';
import { openAccountFacebook } from '@/api/OpenAccountList.js';
export default {
  props: {
    queryAgency: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules: {
        id: [
          {
            required: true,
            message: '请选择代理账单',
            trigger: 'blur',
          },
        ],
        requestIds: [
          {
            required: true,
            message: '请输入申请ID',
            trigger: 'blur',
          },
        ],
      },
      openAccount: {
        id: '',
        requestIds: '',
      },
      // queryAgency: [],
      showGetId: false,
    };
  },
  created() {
    // this.getQueryAgency();
  },
  methods: {
    // getQueryAgency() {
    //   queryAgency().then((res) => {
    //     this.queryAgency = res.data;
    //   });
    // },
    checkData() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let params = {
          agencyId: this.openAccount.id,
          requestIds: this.openAccount.requestIds.split(','),
        };
        this.submitForm(params);
      });
    },
    submitForm(params) {
      openAccountFacebook(params).then((res) => {
        if (res.code == 0) {
          this.$message.success('申请成功');
          this.$emit('cancel');
          this.$emit('refreshList');
        }
      });
    },
    // 切换显示获取申请ID
    setShowGetId(bool) {
      this.showGetId = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.openAccountFb {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  padding: 10px;

  .header {
    border-bottom: 1px solid #eee;
  }

  .formItem {
    width: 100%;
  }
}
.lookGetIdImg {
  width: 100%;
}
</style>
