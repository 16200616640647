// 开户列表
import request from '@/utils/request';

const mockUrl = ''; //'https://mock.apifox.cn/m1/2851801-0-default/api';
// 开户列表
export function queryOpenAccountPage(data) {
  return request({
    url: mockUrl + '/blueMedia/queryOpenAccountPage',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 提交开户
export function openAccountFacebook(data) {
  return request({
    url: `/blueMedia/openAccountFacebook`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 获取开户详情
export function openAccountdetail(params) {
  return request({
    url: mockUrl + '/blueMedia/openAccountDetail',
    method: 'post',
    params,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
