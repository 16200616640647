<template>
  <el-container class="pr openAccountList">
    <el-tabs
      type="border-card"
      v-model="activeTab"
      class="w-100"
    >
      <el-tab-pane name="Facebook">
        <span slot="label"> Facebook </span>
        <facebook
          @toggleShowDetail="toggleShowDetail"
          @setOpearData="setOpearData"
        ></facebook>
      </el-tab-pane>
      <!-- <el-tab-pane name="Tiktok">
        <span slot="label"> Tiktok </span>
        <tiktok
          :formatterParams="formatterParams"
          @toggleShowDetail="toggleShowDetail"
          @setOpearData="setOpearData"
        ></tiktok>
      </el-tab-pane>
      <el-tab-pane name="Google">
        <span slot="label">Google</span>
        <google
          :formatterParams="formatterParams"
          @toggleShowDetail="toggleShowDetail"
          @setOpearData="setOpearData"
        ></google>
      </el-tab-pane> -->
    </el-tabs>
    <detail
      :showDialog="showDetail"
      :data="opearData"
      @toggleShowDialog="toggleShowDetail"
    ></detail>
  </el-container>
</template>

<script>
import Facebook from './components/Facebook';
// import Tiktok from './components/Tiktok';
// import Google from './components/Google';
import Detail from './components/common/Detail.vue';
import { openAccountdetail } from '@/api/OpenAccountList';

export default {
  components: {
    Facebook,
    // Tiktok,
    // Google,
    Detail,
  },
  data() {
    return {
      activeTab: 'Facebook',
      showDetail: false,
      opearData: {}, // 操作的数据
    };
  },
  methods: {
    // 切换详情展示
    toggleShowDetail(bool = false) {
      this.showDetail = bool;
    },
    setOpearData({ id }) {
      this.opearData = {};
      const params = {
        id,
      };
      openAccountdetail(params).then((res) => {
        this.opearData = res.data || {};
      });
    },
  },
};
</script>
<style lang="scss">
.openAccountList {
  .el-tabs__content {
    padding: 0;
  }
  .el-table .caret-wrapper {
    width: 0;
  }
}
</style>

<style lang="scss">
@import '@/styles/commonly';
.cus-tab-wrap {
  width: 100%;
  .el-tabs__item {
    color: rgb(81, 81, 81);
    padding: 0 16px;
    font-size: 17px;
    border-radius: 6px 6px 0 0;
    border: none;
    background: rgb(231, 232, 235);
    margin-right: 8px;
    font-weight: 400;
    text-align: left;
    min-width: 176px;
  }
  &.el-tabs--border-card {
    border: none;
    box-shadow: none;
    & > .el-tabs__header {
      @extend .bg-theme;
      border-bottom: none;
    }
  }
  .el-tabs__content {
    @extend .flex-1;
    padding: 8px 16px 16px;
  }
}
</style>
