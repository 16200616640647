<template>
  <el-dialog
    title="提交开户详情-FB"
    :visible="showDialog"
    width="800px"
    @close="$emit('toggleShowDialog', false)"
  >
    <div class="detail">
      <el-descriptions
        title=""
        :column="1"
      >
        <el-descriptions-item label="申请ID">{{ data.fbRequestId }}</el-descriptions-item>
        <el-descriptions-item label="开户主体"> {{ data.entityName }}</el-descriptions-item>
        <el-descriptions-item label="开户数量"> {{ data.openNum }}</el-descriptions-item>
        <el-descriptions-item label="开户申请时间"> {{ data.applyTime }}</el-descriptions-item>
      </el-descriptions>
    </div>

    <el-card
      class="box-card"
      header="下户账户"
    >
      <el-table :data="data.accounts">
        <el-table-column
          label="账户ID"
          prop="accountId"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="accountName"
        ></el-table-column>
      </el-table>
    </el-card>

    <div
      slot="footer"
      class="border-top-1 pt-10"
    >
      <el-button
        type="primary"
        @click="$emit('toggleShowDialog', false)"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
    },
    showDialog: {
      type: Boolean,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style scope lang="scss">
.detail {
  max-height: 80vh;
  overflow: scroll;
}
</style>
